
  import { Component, Mixins, Watch } from "vue-property-decorator";
  import mixinJoin from "@/mixins/mixinJoin";
  import validate from "@/validations/validate";
  import {
    validationEmployeeRoute,
    validationEmployeeStep1,
    validationEmployeeStep2,
    validationEmployeeStep3,
    validationEmployeeStep4,
  } from "@/validations/employee";
  import validate_api from "@/validations/validate_api";
  import * as util from "@/util";
  import { validationDependant, validationSpouce } from "@/validations/family";
  import { Provide } from "@/library/provide";
  import LProgress from "@/components/l/Progress.vue";
  import LWizard from "@/components/l/Wizard.vue";
  import LBread from "@/components/l/Bread.vue";
  import EmployeejoinAddress from "@/components/employeejoin/Address.vue";
  import EmployeejoinTax from "@/components/employeejoin/Tax.vue";
  import EmployeejoinSpouce from "@/components/employeejoin/Spouce.vue";
  import EmployeejoinDependants from "@/components/employeejoin/Dependants.vue";
  import EmployeejoinEmployeeinfo from "@/components/employeejoin/Employeeinfo.vue";
  import LCard from "@/components/l/Card.vue";
  import FButton from "@/components/f/Button.vue";
  import EmployeejoinDependantsdisp from "@/components/employeejoin/Dependantsdisp.vue";
  import { createCustomized, hasCustomizeTag, getTagData } from "@/customize";

  @Component({
    components: {
      LProgress,
      LWizard,
      LBread,
      EmployeejoinBase: createCustomized(() => import("@/components/employeejoin/Base.vue"), {
        apu: () => import("@/components/employeejoin/Baseapu.vue"),
      }),
      EmployeejoinAddress: createCustomized(() => import("@/components/employeejoin/Address.vue"), {
        apu: () => import("@/components/employeejoin/Addressapu.vue"),
      }),
      EmployeejoinBank: createCustomized(() => import("@/components/employeejoin/Bank.vue"), {
        apu: () => import("@/components/employeejoin/Bankapu.vue"),
      }),
      EmployeejoinTax,
      EmployeejoinSpouce,
      EmployeejoinDependants,
      EmployeejoinEmployeeinfo,
      LCard,
      FButton,
      EmployeejoinDependantsdisp,
    },
  })
  export default class Main extends Mixins(mixinJoin) {
    async created() {
      this.m.initState({ name: "step" });
      this.m.setState({ name: "step", val: 0.1 });
      this.m.initEditManager({ name: "join" });
    }

    @Watch("global")
    async fetchedGlobal(global) {
      await getTagData(global.company_id);
      if (global) {
        const each_employee_infos = {};
        this.global.employee_infos.forEach((employee_info) => {
          each_employee_infos[employee_info.id] = [];
        });

        this.m.edit_manager.join.edit({
          employee: {
            ...global.employee,
            is_bank1: hasCustomizeTag("apu") ? 1 : 0,
          },
          spouce: {
            employee_id: global.employee.id,
            kana: null,
            name: null,
            birth_date: null,
            mynum: null,
            is_housemate: 1,
            disability_type: 0,
            income: 0,
            retire_income: 0,
            other_income: 0,
            is_not_live: 0,
            address: null,
            not_live_money: null,
            is_special_disability_housemate: 1,
            disability_reason: null,
          },
          dependants: [],
          dependant_num: 0,
          each_employee_infos,
        });

        if (hasCustomizeTag("apu")) {
          this.employee.is_bank1 = 1;
        }

        //区分による税額表区分のセット
        if (this.employee.hiring_type == 0 || this.employee.hiring_type == 3) {
          //アルバイト・その他
          this.employee.tax_type = 1;
        } else if (this.employee.hiring_type == 1) {
          //社員
          this.employee.tax_type = 0;
        } else if (this.employee.hiring_type == 2) {
          //役員
          this.employee.tax_type = 0;
        }

        //整合性
        if (this.employee_type == 1 || this.employee_type == 2) {
          //シフトのみ or シフト・勤怠のみ
          this.employee.birth_date = "1990-01-01";
          this.employee.zip = "000-0000";
          this.employee.address = this.employee.address ?? "不明";
          this.employee.tax_type = 1;
        }
        if (this.employee_type == 1 || this.employee_type == 2 || this.employee_type == 5) {
          //年末調整のみ
          this.employee.is_bank1 = 0;
          this.employee.is_bank2 = 0;
        }
      }
    }

    get employee() {
      return this.m.edit_manager.join.object.employee;
    }
    get dependants() {
      return this.m.edit_manager.join.object.dependants;
    }
    get spouce() {
      return this.m.edit_manager.join.object.spouce;
    }
    get dependant_num() {
      return this.m.edit_manager.join.object.dependant_num;
    }

    async clickNext() {
      if (this.step_key == "initial") {
        this.m.setState({ name: "step", val: this.steps[this.steps["initial"].next_index].index });
      } else if (this.step_key == "base") {
        if (validate(validationEmployeeStep1(this.employee, true))) {
          const res = await util.post(
            "join/confirm_mail",
            { token: this.query.token, mail: this.employee.mail, employee_id: this.employee.id },
            false,
          );
          if (validate_api(res, ["使用できないメールアドレスが入力されています。"])) {
            this.m.setState({ name: "step", val: this.steps[this.steps["base"].next_index].index });
          }
        }
      } else if (this.step_key == "address") {
        let error = validationEmployeeStep2(this.employee);
        error = error.concat(validationEmployeeRoute(this.employee));
        if (validate(error)) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["address"].next_index].index,
          });
        }
      } else if (this.step_key == "bank") {
        if (validate(validationEmployeeStep3(this.employee))) {
          this.m.setState({ name: "step", val: this.steps[this.steps["bank"].next_index].index });
        }
      } else if (this.step_key == "tax") {
        if (validate(validationEmployeeStep4(this.employee, true))) {
          if (this.employee.tax_type == 0) {
            this.m.setState({
              name: "step",
              val: this.steps[this.steps["tax"].next_kou_index].index,
            });
          } else {
            this.m.setState({
              name: "step",
              val: this.steps[this.steps["tax"].next_otsu_index].index,
            });
          }
        }
      } else if (this.step_key == "spouce") {
        if (
          this.employee.is_spouce == 0 ||
          this.employee.is_spouce_deduction == 0 ||
          validate(validationSpouce(null, { spouce: this.spouce, index: true }))
        ) {
          this.m.setState({ name: "step", val: this.steps[this.steps["spouce"].next_index].index });
        }
      } else if (this.step_key == "dependant") {
        let error = [];
        if (this.dependant_num == 1) {
          error = error.concat(
            validationDependant(null, {
              dependant: this.dependants[0],
              is_spouce: this.employee.is_spouce == 1,
              index: 0,
            }),
          );
        } else {
          for (let index = 0; index < this.dependant_num; index++) {
            error = error.concat(
              validationDependant(null, {
                dependant: this.dependants[index],
                is_spouce: this.employee.is_spouce == 1,
                index: index + 1,
              }),
            );
          }
        }
        if (validate(error)) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["dependant"].next_index].index,
          });
        }
      } else if (this.step_key == "employee_info") {
        this.m.setState({
          name: "step",
          val: this.steps[this.steps["employee_info"].next_index].index,
        });
      } else if (this.step_key == "confirm") {
        this.m.update({
          name: "join",
          args: {
            token: this.query.token,
            employee: this.m.edit_manager.join.object.employee,
            spouce: this.m.edit_manager.join.object.spouce,
            dependants: this.m.edit_manager.join.object.dependants,
            each_employee_infos: this.m.edit_manager.join.object.each_employee_infos,
            employee_id: this.employee.id,
          },
        });
      }
    }
    clickPrev() {
      if (this.step_key == "base") {
        this.m.setState({ name: "step", val: this.steps[this.steps["base"].prev_index].index });
      } else if (this.step_key == "address") {
        this.m.setState({ name: "step", val: this.steps[this.steps["address"].prev_index].index });
      } else if (this.step_key == "bank") {
        this.m.setState({ name: "step", val: this.steps[this.steps["bank"].prev_index].index });
      } else if (this.step_key == "tax") {
        this.m.setState({ name: "step", val: this.steps[this.steps["tax"].prev_index].index });
      } else if (this.step_key == "spouce") {
        this.m.setState({ name: "step", val: this.steps[this.steps["spouce"].prev_index].index });
      } else if (this.step_key == "dependant") {
        this.m.setState({
          name: "step",
          val: this.steps[this.steps["dependant"].prev_index].index,
        });
      } else if (this.step_key == "employee_info") {
        if (this.employee.tax_type == 0) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["employee_info"].prev_kou_index].index,
          });
        } else {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["employee_info"].prev_otsu_index].index,
          });
        }
      } else if (this.step_key == "confirm") {
        if (this.employee.tax_type == 0) {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["confirm"].prev_kou_index].index,
          });
        } else {
          this.m.setState({
            name: "step",
            val: this.steps[this.steps["confirm"].prev_otsu_index].index,
          });
        }
      }
    }

    @Provide("employee_type")
    get employee_type() {
      return this.employee.type;
    }

    @Provide("is_edit")
    get is_edit() {
      return this.step < this.steps["confirm"].index;
    }
    @Provide("is_add")
    get is_add() {
      return false;
    }

    @Provide("force_sp")
    get force_sp() {
      return true;
    }
  }
