import { Component, Mixins, Vue } from "vue-property-decorator";
import utilMixins from "@/mixins";
import vuex_data from "@/vuex/vuex_data";
import vuex_manager from "@/vuex/vuex_manager";
import * as util from "@/util";

@Component
export default class mixinJoin extends Mixins(utilMixins) {
  get m() {
    return vuex_manager.join_manager;
  }
  get global() {
    return vuex_data.join_global.get(this);
  }

  get token() {
    return this.query.token;
  }

  get employee_type() {
    return this.global.employee.type;
  }

  get step() {
    return this.m.state.step;
  }
  set step(val) {
    this.m.setState({ name: "step", val });
  }
  get step_hash() {
    const result = {};
    Object.keys(this.steps).forEach((key) => {
      result[this.steps[key].index] = key;
    });
    return result;
  }
  get step_key() {
    return this.step_hash[this.step];
  }
  get steps() {
    const steps = {};
    let index = 1;

    steps["initial"] = {
      index: 0.1,
      name: "",
      is_header: false,
      is_next: true,
      next_index: "base",
      next: util.$t("start"),
    };

    if (this.employee_type != 1 && this.employee_type != 2) {
      steps["base"] = {
        index: index++,
        name: util.$t("base_info"),
        is_prev: true,
        is_next: true,
        next_index: "address",
        prev_index: "initial",
      };
      steps["address"] = {
        index: index++,
        name: util.$t("address_info"),
        is_prev: true,
        is_next: true,
        next_index: this.employee_type != 5 ? "bank" : "tax",
        prev_index: "base",
      };
      if (this.employee_type != 5)
        steps["bank"] = {
          index: index++,
          name: util.$t("bank_info"),
          is_prev: true,
          is_next: true,
          next_index: "tax",
          prev_index: "address",
        };
      steps["tax"] = {
        index: index++,
        name: util.$t("self_info"),
        is_prev: true,
        is_next: true,
        next_kou_index: "spouce",
        next_otsu_index: this.global.employee_infos.length != 0 ? "employee_info" : "confirm",
        prev_index: this.employee_type != 5 ? "bank" : "address",
      };
      steps["spouce"] = {
        index: index++,
        name: util.$t("spouce_info"),
        is_prev: true,
        is_next: true,
        next_index: "dependant",
        prev_index: "tax",
      };
      steps["dependant"] = {
        index: index++,
        name: util.$t("dependant_info"),
        is_prev: true,
        is_next: true,
        next_index: this.global.employee_infos.length != 0 ? "employee_info" : "confirm",
        prev_index: "spouce",
      };
      if (this.global.employee_infos.length != 0)
        steps["employee_info"] = {
          index: index++,
          name: util.$t("custom_info"),
          is_prev: true,
          is_next: true,
          next_index: "confirm",
          prev_kou_index: "dependant",
          prev_otsu_index: "tax",
        };
      steps["confirm"] = {
        index: index++,
        name: util.$t("confirm"),
        is_prev: true,
        is_next: true,
        next: util.$t("determine"),
        next_index: "finish",
        prev_kou_index: this.global.employee_infos.length != 0 ? "employee_info" : "dependant",
        prev_otsu_index: this.global.employee_infos.length != 0 ? "employee_info" : "tax",
      };
    } else {
      steps["base"] = {
        index: index++,
        name: util.$t("base_info"),
        is_prev: true,
        is_next: true,
        next_index: this.global.employee_infos.length != 0 ? "employee_info" : "confirm",
        prev_index: "initial",
      };
      if (this.global.employee_infos.length != 0)
        steps["employee_info"] = {
          index: index++,
          name: util.$t("custom_info"),
          is_prev: true,
          is_next: true,
          next_index: "confirm",
          prev_kou_index: "base",
          prev_otsu_index: "base",
        };
      steps["confirm"] = {
        index: index++,
        name: util.$t("confirm"),
        is_prev: true,
        is_next: true,
        next: util.$t("determine"),
        next_index: "finish",
        prev_kou_index: this.global.employee_infos.length != 0 ? "employee_info" : "base",
        prev_otsu_index: this.global.employee_infos.length != 0 ? "employee_info" : "base",
      };
    }

    steps["finish"] = { index: 10, name: "", is_header: false };

    return steps;
  }
}
